import 'bootstrap/dist/js/bootstrap.bundle'
import '@hotwired/turbo-rails'
import './organizations/show'

(function() {
  document.querySelectorAll('[data-href]').forEach(el => {
    el.addEventListener('click', () => { window.document.location = el.dataset.href; } )
  })

  const sidebar = document.querySelector('.sidebar');
  const sidebarToggles = document.querySelectorAll('#sidebarToggle, #sidebarToggleTop');

  if (sidebar) {

    const collapseEl = sidebar.querySelector('.collapse');
    const collapseElementList = [].slice.call(document.querySelectorAll('.sidebar .collapse'))
    const sidebarCollapseList = collapseElementList.map(function (collapseEl) {
      return new bootstrap.Collapse(collapseEl, { toggle: false });
    });

    for (let toggle of sidebarToggles) {

      // Toggle the side navigation
      toggle.addEventListener('click', function(e) {
        document.body.classList.toggle('sidebar-toggled');
        sidebar.classList.toggle('toggled');

        if (sidebar.classList.contains('toggled')) {
          for (let bsCollapse of sidebarCollapseList) {
            bsCollapse.hide();
          }
        };
      });
    }

    // Close any open menu accordions when window is resized below 768px
    window.addEventListener('resize', function() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw < 768) {
        for (let bsCollapse of sidebarCollapseList) {
          bsCollapse.hide();
        }
      };
    });
  }

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over

  const fixedNaigation = document.querySelector('body.fixed-nav .sidebar');

  if (fixedNaigation) {
    fixedNaigation.on('mousewheel DOMMouseScroll wheel', function(e) {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw > 768) {
        const e0 = e.originalEvent,
          delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      }
    });
  }

  const scrollToTop = document.querySelector('.scroll-to-top');

  if (scrollToTop) {

    // Scroll to top button appear
    window.addEventListener('scroll', function() {
      const scrollDistance = window.pageYOffset;

      //check if user is scrolling up
      if (scrollDistance > 100) {
        scrollToTop.style.display = 'block';
      } else {
        scrollToTop.style.display = 'none';
      }
    });
  }

  const setupTooltips = () => {
    document
      .querySelectorAll(`[data-bs-toggle="tooltip"`)
      .forEach(el => { new bootstrap.Tooltip(el) });
  };

  setupTooltips();
  document.addEventListener("turbo:load", setupTooltips);
})();

document.addEventListener("turbo:frame-missing", event => event.preventDefault())
