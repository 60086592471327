import { Chart } from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { de } from "date-fns/locale"

function draw() {
  const container = document.querySelector("#jobposting-stages-chart");
  if (!container) { return }

  const counts = JSON.parse(container.dataset.counts);
  const labels = JSON.parse(container.dataset.labels);

  debugger

  new Chart(container, {
    type: "doughnut",
    data: {
      labels: labels,
      datasets: [{ data: counts }]
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            sort: (a, b) => a.text.toLowerCase() > b.text.toLowerCase()
          },
        }
      }
    }
  });
}

document.documentElement.addEventListener("turbo:load", draw);
